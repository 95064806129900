import React from "react";

const CartIcon2 = () => {
  return (
    <svg
      width={61}
      height={63}
      viewBox="0 0 61 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.354492"
        y="0.302734"
        width="60.2874"
        height="62.6974"
        rx="30.1437"
        fill="#009E4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7493 19.8749C18.7493 19.2245 19.2765 18.6973 19.9269 18.6973H24.6209C25.2713 18.6973 25.7986 19.2245 25.7986 19.8749V19.8749C25.7986 20.5253 26.3258 21.0525 26.9762 21.0525H40.8842C41.6035 21.0525 42.1561 21.6895 42.0545 22.4016L40.712 31.8135C40.6289 32.396 40.1301 32.8287 39.5417 32.8287H26.4789C25.7617 32.8287 25.211 33.4645 25.3134 34.1744V34.1744C25.3969 34.7539 25.8935 35.184 26.4789 35.184H38.8772C39.5944 35.184 40.1451 35.8197 40.0427 36.5297V36.5297C39.9592 37.1091 39.4626 37.5392 38.8772 37.5392H24.4636C23.8758 37.5392 23.3774 37.1075 23.2935 36.5257L22.7617 32.8378C22.761 32.833 22.7647 32.8287 22.7696 32.8287V32.8287C22.7744 32.8287 22.7781 32.8244 22.7775 32.8197L21.2438 22.0678C21.1608 21.4852 20.6619 21.0525 20.0735 21.0525H19.9269C19.2765 21.0525 18.7493 20.5253 18.7493 19.8749V19.8749ZM25.1714 23.4078C24.4521 23.4078 23.8995 24.0448 24.0011 24.7569L24.6717 29.4582C24.7547 30.0407 25.2536 30.4735 25.842 30.4735H37.504C38.0924 30.4735 38.5912 30.0407 38.6743 29.4582L39.3449 24.7569C39.4465 24.0448 38.8939 23.4078 38.1746 23.4078H25.1714Z"
        fill="white"
      />
      <path
        d="M37.5474 44.605C38.8451 44.605 39.8972 43.5505 39.8972 42.2497C39.8972 40.9489 38.8451 39.8945 37.5474 39.8945C36.2497 39.8945 35.1976 40.9489 35.1976 42.2497C35.1976 43.5505 36.2497 44.605 37.5474 44.605Z"
        fill="white"
      />
      <path
        d="M28.1483 42.2497C28.1483 43.5505 27.0963 44.605 25.7986 44.605C24.5008 44.605 23.4488 43.5505 23.4488 42.2497C23.4488 40.9489 24.5008 39.8945 25.7986 39.8945C27.0963 39.8945 28.1483 40.9489 28.1483 42.2497Z"
        fill="white"
      />
    </svg>
  );
};

export default CartIcon2;
