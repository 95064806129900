import React, { useState } from "react";


const TrashIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"

      fill="current"
      style={{ display: "inline-block" }}
    >
      <path
        d="M6.03125 1.5C5.9375 1.5 5.875 1.5625 5.8125 1.625L5.21875 2.5H9.75L9.15625 1.625C9.125 1.5625 9.03125 1.5 8.9375 1.5H6.03125ZM11.5625 2.5H11.9688H13.5H13.75C14.1562 2.5 14.5 2.84375 14.5 3.25C14.5 3.6875 14.1562 4 13.75 4H13.375L12.625 14.1562C12.5312 15.2188 11.6875 16 10.625 16H4.34375C3.28125 16 2.4375 15.2188 2.34375 14.1562L1.59375 4H1.25C0.8125 4 0.5 3.6875 0.5 3.25C0.5 2.84375 0.8125 2.5 1.25 2.5H1.5H3H3.40625L4.5625 0.78125C4.875 0.3125 5.4375 0 6.03125 0H8.9375C9.53125 0 10.0938 0.3125 10.4062 0.78125L11.5625 2.5ZM11.875 4H3.09375L3.84375 14.0625C3.875 14.3125 4.09375 14.5 4.34375 14.5H10.625C10.875 14.5 11.0938 14.3125 11.125 14.0625L11.875 4Z"
        fill="#000" // Change the hover color here
      />
    </svg>
  );
};

export default TrashIcon;
