import React from "react";
import { formatPrice } from "@/lib/util";
import TrashIcon from "@/modules/common/icons/trash-icon";
import CtaButton from "@/modules/common/components/cta-button";

export default function CartWidget({
  cart,
  isVisible,
  isHovered = false,
  updateLineItem,
}) {
  return (
    <div
      className={`w-full relative transition-opacity duration-1000 shadow-lg transition-transform duration-300 ease-in-out transform
       ${
         isVisible || isHovered
           ? "opacity-100 translate-y-6 pointer-events-auto z-[100]"
           : "opacity-0 -translate-y-4 pointer-events-none"
       }`}
    >
      <div className="w-[340px] sm:w-[400px] border-2 border-[#858481] rounded-2xl p-4 bg-customWhite absolute right-[-55px] sm:right-0 -top-5">
        <p className="text-center text-lg text-customGray font-sans font-bold mb-4">
          Cart
        </p>
        <div className="mb-2">
          {cart?.items?.map((item, index) => {
            const removeCartItem = async () => {
              await updateLineItem(item.id, 0);
            };
            return (
              <div
                className="flex flex-row justify-between mb-4 gap-2"
                key={index}
              >
                <div className="rounded-xl h-full flex justify-center items-center">
                  <img
                    src={item?.thumbnail}
                    className="h-20 min-w-[60px] max-w-[60px] sm:min-w-[80px] max-w-[80px] rounded-xl object-cover bg-[#C1E3CA]"
                  />
                </div>
                <div className="min-w-[190px] sm:min-w-[200px]">
                  <p className="text-customGray font-sans font-medium">
                    {item?.title}
                  </p>
                  <p className="text-sm">Variant: {item?.variant?.title}</p>
                  <p className="text-sm text-customGray font-sans font-medium">
                    Quantity {item?.quantity}
                  </p>
                  <button
                    id={"removeButton"}
                    name={"removeButton"}
                    className={`flex flex-row justify-center items-center text-[#B6B6B6] text-sm hover:text-[#9C1818] focus:outline-none hover:underline`}
                    onClick={removeCartItem}
                  >
                    <span className="mr-1">
                      <TrashIcon />
                    </span>
                    Remove
                  </button>
                </div>
                <div>{formatPrice(item?.unit_price)}</div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-row justify-between items-center pt-2">
          <p>
            <span className="text-customGray font-sans font-bold">
              Subtotal
            </span>{" "}
            {/* (excl. taxes) */}
          </p>
          <p className="text-customGray font-sans font-bold">
            {formatPrice(cart?.subtotal)}
          </p>
        </div>
        {cart?.items?.length > 0 && (
          <div className="w-full flex flex-col mt-2">
            <CtaButton href="/licensee/cart" bgColor="black">
              Go to cart
            </CtaButton>
          </div>
        )}
      </div>
    </div>
  );
}
