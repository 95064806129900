import React, { useEffect, useState } from "react";
import { getCustomer, getCustomerId } from "../data";

export default function useCustomer() {
  const [customer, setCustomer] = useState(null);

  const [customerId, setCustomerId] = useState(null);
  useEffect(() => {
    const getCustomerFunc = async () => {
      const customer = await getCustomer();
      setCustomer(customer);
    };

    const getCustomerIdFunc = async () => {
      const customerId = await getCustomerId();
      if (customerId) {
        setCustomerId(customerId);
        getCustomerFunc();
      }
    };
    getCustomerIdFunc();
  }, []);

  return { customer, customerId };
}
